import http from "./httpService";

const apiEndpoint = "/billing-profiles";

export async function getAllProfiles() {
  let url = `${apiEndpoint}/`;
  try {
    return await http.get(url);
  } catch (error) {
    return { error };
  }
}

export async function createProfiles(data) {
  let url = `${apiEndpoint}/`;
  try {
    return await http.post(url, data);
  } catch (error) {
    return { error };
  }
}
export async function updateProfile(data) {
  let url = `${apiEndpoint}/`;
  try {
    return await http.put(url, data);
  } catch (error) {
    return { error };
  }
}
export async function deleteProfile(id) {
  let url = `${apiEndpoint}/${id}`;
  try {
    return await http.delete(url);
  } catch (error) {
    return { error };
  }
}

export async function generateBilling(startDate, endDate, month, year) {
  let url = `${apiEndpoint}/generate-billing`;
  try {
    return await http.post(url, {
      startDate: startDate,
      endDate: endDate,
      month,
      year,
    });
  } catch (error) {
    return { error };
  }
}

export async function updateMultipleStatus(selectedBillID, status) {
  let url = `${apiEndpoint}/update-multiple-status`;
  try {
    return await http.post(url, {
      selectedBillID,
      status,
    });
  } catch (error) {
    return { error };
  }
}
export async function updateBillingStatus(
  BillID,
  status,
  paidAmount,
  denialCode,
  denialReason
) {
  let url = `${apiEndpoint}/update-billing-status`;
  try {
    return await http.post(url, {
      BillID,
      status,
      paidAmount,
      denialCode,
      denialReason,
    });
  } catch (error) {
    return { error };
  }
}

export async function getBilling(month, year) {
  let url = `${apiEndpoint}/get-billing`;
  try {
    return await http.post(url, {
      month: month,
      year: year,
    });
  } catch (error) {
    return { error };
  }
}

export async function donwloadBilling(month, year) {
  let url = `${apiEndpoint}/download-billing`;
  try {
    return await http.post(
      url,
      {
        month: month,
        year: year,
      },
      { responseType: "blob" }
    );
  } catch (error) {
    return { error };
  }
}
