// // Import necessary dependencies
import React, { useEffect, useState } from "react";
import {
  donwloadBilling,
  generateBilling,
  getBilling,
  updateBillingStatus,
  updateMultipleStatus,
} from "../services/billingProfileService";
import { CSVLink } from "react-csv";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "./Loader";
import { HiArrowUpTray } from "react-icons/hi2";
import { toast } from "react-toastify";
import { MdOutlineCancel } from "react-icons/md";

// Utility function to get the start and end dates of the selected month
const getMonthStartAndEndDates = (year, month) => {
  // Ensure `month` is within 1-12 range
  if (month < 1 || month > 12) {
    throw new Error("Month must be between 1 and 12.");
  }

  // Start date: first day of the month
  const startDate = new Date(year, month - 1, 1);

  // End date: last day of the month
  const endDate = new Date(year, month, 0);
  console.log(startDate, endDate);
  // Format date as YYYY-MM-DD without converting to UTC
  const formatDate = (date) => {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  return {
    start: formatDate(startDate),
    end: formatDate(endDate),
  };
};

const GenerateBilling = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedBill, setSelectedBill] = useState({});
  const [denialCode, setDenialCode] = useState("");
  const [denialReason, setDenialReason] = useState("");
  // Calculate the default year and month (one month prior to current date)
  const currentDate = new Date();
  const defaultMonth =
    currentDate.getMonth() === 0 ? 12 : currentDate.getMonth(); // December if January (0-based month)
  const defaultYear =
    currentDate.getMonth() === 0
      ? currentDate.getFullYear() - 1
      : currentDate.getFullYear();
  const [selectedBillId, setSeleectedBillId] = useState([]);
  const [resGroup, setResGroup] = useState("All Residents");
  const [loading, setLoading] = useState(true);
  // State to manage the selected year and month
  const [year, setYear] = useState(defaultYear);
  const [showRegenerate, setShowRegenerate] = useState(false);
  const [month, setMonth] = useState(defaultMonth);
  const [data, setData] = useState({
    "Full Month Stay": [],
    "Started Mid-Month": [],
    "Left Mid-Month": [],
    "All Residents": [],
  });
  // Handle submit
  const handleSubmit = async () => {
    setLoading(true);
    setCurrentPage(1);
    setData({
      "Full Month Stay": [],
      "Started Mid-Month": [],
      "Left Mid-Month": [],
      "All Residents": [],
    });
    const { start, end } = getMonthStartAndEndDates(year, month);
    try {
      const response = await generateBilling(start, end, month, year);
      console.log(response);
      if (response.data.success) {
        fetchData();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  async function fetchData() {
    setSeleectedBillId([]);
    const res = await getBilling(month, year);
    console.log(res.data);
    if (res.data["All Residents"]?.length == 0) {
      setShowRegenerate(false);
      setData({
        "Full Month Stay": [],
        "Started Mid-Month": [],
        "Left Mid-Month": [],
        "All Residents": [],
      });
      setLoading(false);
    } else {
      setLoading(false);
      setShowRegenerate(true);
      setData({ ...data, ...res.data });
    }
  }
  useEffect(() => {
    if (year && month) {
      fetchData();
    }
  }, [year, month]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;

  const totalPages = Math.ceil(data[resGroup].length / itemsPerPage);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const paginatedData = data[resGroup].slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  function dateShower(date) {
    if (!date) return "-";
    date = new Date(date).toISOString();
    if (date?.length > 11) {
      let dateString = date.slice(0, 10);
      dateString = dateString.split("-");
      return dateString[1] + "-" + dateString[2] + "-" + dateString[0];
    } else return "";
  }

  const [paidAmount, setPaidAmount] = useState(
    Number(selectedBill.PaidAmount) || ""
  ); // Default paid amount as string
  const [remainingAmount, setRemainingAmount] = useState(0); // Default remaining amount as a number
  const [totalAmount, setTotalAmount] = useState(0); // Default remaining amount as a number
  useEffect(() => {
    // Update remaining amount based on the selected bill's total and paid amount
    const total = Number(selectedBill.TotalAmount) || 0;
    const paid = Number(selectedBill.PaidAmount) || 0;
    setTotalAmount(total);
    setRemainingAmount(total - paid);
  }, [selectedBill]);
  const handlePaidAmountChange = (e) => {
    const value = e.target.value;

    // Validate input for currency format (up to 2 decimals)
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      const updatedPaidAmount = parseFloat(value || 0); // Parse the value or default to 0
      const total = totalAmount - updatedPaidAmount;
      if (total >= 0) {
        setPaidAmount(value); // Update the paid amount as a string
        setRemainingAmount(total); // Assuming total is $1200. Adjust as needed
      }
    }
  };

  const changeMultiStatus = async (selectedBillId, status, item) => {
    if (status === "Partially Paid") {
      setSelectedBill(item);
      setModalOpen("Partially Paid");
      return;
    }
    if (status === "Denied") {
      setSelectedBill(item);
      setModalOpen("Denied");
      return;
    }
    setLoading(true);
    console.log(selectedBillId, status);
    const res = await updateMultipleStatus(selectedBillId, status);
    console.log(res.data);
    if (res.data?.success) {
      fetchData();
    } else {
      setLoading(false);
      toast.error("Error updating status");
    }
  };
  const updateBillStatus = async (
    BillID,
    status,
    paidAmount,
    denialCode,
    denialReason
  ) => {
    setLoading(true);
    console.log(selectedBillId, status);
    const res = await updateBillingStatus(
      BillID,
      status,
      paidAmount,
      denialCode,
      denialReason
    );
    console.log(res.data);
    if (res.data?.success) {
      fetchData();
      setModalOpen(false);
    } else {
      setLoading(false);
      toast.error("Error updating status");
    }
  };

  const handleDownload = async () => {
    const res = await donwloadBilling(month, year);
    console.log(res);
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const a = document.createElement("a");
    a.href = url;
    a.download = "residents.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <div className="p-3 flex flex-col ">
      <h2 className="text-2xl font-semibold text-gray-700 mb-8">Billing</h2>
      <div className="grid grid-cols-2">
        <div className="flex flex-row w-min gap-4 justify-start items-center mb-8 text-lg">
          {/* Year Selector */}
          <div className="rounded-lg w-fit p-2 bg-gray-100 border-none  flex flex-row items-center">
            <span className="text-gray-400">Year: </span>
            <select
              value={year}
              onChange={(e) => setYear(Number(e.target.value))}
              className="block border-none focus:outline-none focus:ring-0 cursor-pointer"
            >
              {Array.from(
                { length: 2 },
                (_, i) => new Date().getFullYear() - 1 + i
              ).map((yr) => (
                <option key={yr} value={yr}>
                  {yr}
                </option>
              ))}
            </select>
          </div>
          <div className="rounded-lg w-auto p-2 bg-gray-100 border-none flex flex-row items-center">
            <span className="text-gray-400">Month: </span>
            <select
              value={month}
              onChange={(e) => setMonth(Number(e.target.value))}
              className="block border-none focus:outline-none focus:ring-0 cursor-pointer"
            >
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {new Date(0, i).toLocaleString("default", {
                    month: "long",
                  })}
                </option>
              ))}
            </select>
          </div>
          <div>
            {!showRegenerate ? (
              <button
                onClick={handleSubmit}
                className="border-2 w-max px-4 py-1.5 bg-inherit  text-teal-700 border-teal-700  rounded-lg hover:bg-teal-700 hover:text-white  disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-200"
                disabled={loading}
              >
                Generate
              </button>
            ) : (
              <button
                disabled={loading}
                onClick={handleSubmit}
                className="border-2 w-max px-4 py-1.5 bg-inherit  text-teal-700 border-teal-700  rounded-lg hover:bg-teal-700 hover:text-white  disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-200"
              >
                Re-Generate
              </button>
            )}
          </div>
        </div>
        <div>
          <div className="flex justify-end items-center">
            {data["All Residents"]?.length > 0 && (
              // <CSVLink
              //   data={
              //     data["All Residents"].map((res) => {
              //       return {
              //         ResFirstName: res.ResFirstName,
              //         ResLastName: res.ResLastName,
              //         SSN: res.SSN,
              //         PMI: res.PMI,
              //         ResBirthDate: res?.ResBirthDate
              //           ? dateShower(res.ResBirthDate)
              //           : null,
              //         PriorAuth: res.PriorAuth,
              //         StartDate: dateShower(res.GuestInDate),
              //         GuestInDate: dateShower(res.GuestInDate),
              //         EndDate: res.DateOut ? dateShower(res.DateOut) : "",
              //         DaysStayed: res.DaysStayed,
              //         TotalAmount: res?.Rate
              //           ? Number(res.Rate * res.DaysStayed).toFixed(2)
              //           : "",
              //         PaidAmount: 0,
              //         Month: month,
              //         Year: year,
              //         Supplement: "",
              //         DenialCode: "",
              //         DenialReason: "",
              //         CountyCase: "",
              //         Notes: "",
              //         PAApproval: "",
              //         Status: "To Bill",
              //       };
              //     }) || []
              //   }
              //   filename={
              //     "All Residents, " +
              //     new Date(0, month - 1).toLocaleString("default", {
              //       month: "long",
              //     }) +
              //     ", " +
              //     year +
              //     ".csv"
              //   }
              //   className="border-2 w-max px-4 py-1.5 border-teal-700  rounded-lg bg-teal-700 text-white text-lg no-underline"
              // >
              //   <div className="flex items-center no-underline">
              //     <HiArrowUpTray className="me-2" size={20} />
              //     <span className="no-underline">Export GRH Invoice</span>
              //   </div>
              // </CSVLink>
              <div
                className=" cursor-pointer border-2 w-max px-4 py-1.5 border-teal-700  rounded-lg bg-teal-700 text-white text-lg no-underline"
                onClick={handleDownload}
              >
                <div className="flex items-center no-underline">
                  <HiArrowUpTray className="me-2" size={20} />
                  <span className="no-underline">Export GRH Invoice</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Submit Button */}
      {/* Statistics Section */}
      <div className="flex flex-row justify-between w-full mb-8">
        <div className="flex items-center gap-8">
          {["Expected", "Received", "Denied"].map((title, i) => (
            <div
              key={i}
              className={`flex flex-col items-center justify-center p-4 px-2 max-w-32 h-20 rounded-lg cursor-pointer select-none text-xl
            bg-white  outline outline-2 
            ${
              title == "Expected"
                ? "text-green-600 border-green-600"
                : title == "Received"
                ? "text-blue-600 border-blue-600"
                : title == "Denied"
                ? "text-red-600 border-red-600"
                : ""
            }
          `}
              // onClick={() => this.handleTabClick(i)}
            >
              <span className="">{title}</span>
              <span className={`mt-2 text-xl`}>
                {title === "Expected"
                  ? "$" +
                    Number(
                      Number(
                        Number(
                          data["All Residents"]
                            .filter((it) => it.Status === "Partially Paid")
                            .reduce(
                              (acc, item) =>
                                acc +
                                (Number(item.TotalAmount) -
                                  Number(item.PaidAmount)),
                              0
                            )
                        ).toFixed(2)
                      ) +
                        Number(
                          Number(
                            data["All Residents"]
                              .filter(
                                (it) =>
                                  it.Status === "To Bill" ||
                                  it.Status === "Billed"
                              )
                              .reduce(
                                (acc, item) => acc + Number(item.TotalAmount),
                                0
                              )
                          ).toFixed(2)
                        )
                    ).toFixed(2)
                  : title === "Received"
                  ? "$" +
                    Number(
                      Number(
                        Number(
                          data["All Residents"]
                            .filter((it) => it.Status === "Partially Paid")
                            .reduce(
                              (acc, item) => acc + Number(item.PaidAmount),
                              0
                            )
                        ).toFixed(2)
                      ) +
                        Number(
                          Number(
                            data["All Residents"]
                              .filter((it) => it.Status === "Paid")
                              .reduce(
                                (acc, item) => acc + Number(item.TotalAmount),
                                0
                              )
                          ).toFixed(2)
                        )
                    ).toFixed(2)
                  : title === "Denied"
                  ? "$" +
                    Number(
                      data["All Residents"]
                        .filter((it) => it.Status === "Denied")
                        .reduce(
                          (acc, item) => acc + Number(item.TotalAmount),
                          0
                        )
                    ).toFixed(2)
                  : ""}
              </span>
            </div>
          ))}
        </div>
        <div className="flex flex-row justify-end gap-8 text-lg max-w-min">
          {[
            "All Residents",
            "Full Month Stay",
            "Started Mid-Month",
            "Left Mid-Month",
          ].map((group) => (
            <div
              className={`${
                resGroup === group
                  ? "bg-teal-700 text-white"
                  : "bg-gray-100 text-gray-400"
              } w-max cursor-pointer no-underline text-center rounded-lg focus:outline-none`}
              onClick={() => {
                setResGroup(group);
                setSeleectedBillId([]);
                setCurrentPage(1);
              }}
            >
              <div className="w-full px-4 py-2 rounded-lg  focus:outline-none">
                {group}
                <p className="text-xl pt-2 mb-0">{data[group]?.length}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Table */}
      {isModalOpen == "Partially Paid" ? (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-[768px] p-6">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-semibold text-gray-900">
                Partially Paid Status
              </h2>
              <MdOutlineCancel
                size={40}
                className="text-gray-500 cursor-pointer hover:text-gray-800 focus:outline-none rounded-full bg-white"
                onClick={() => setModalOpen(false)}
              />
            </div>
            <div className="mt-4 flex flex-row justify-between gap-4">
              <div className="w-full">
                <label className="text-gray-600 font-medium block mb-2">
                  Paid Amount
                </label>

                <input
                  type="text"
                  value={paidAmount}
                  onChange={handlePaidAmountChange}
                  className="w-full border-x-0 border-t-0 rounded-none border-gray-300  p-2 focus:outline-none focus:ring-0 "
                  placeholder="Enter paid amount"
                />
              </div>
              <div className=" w-full">
                <label className="text-gray-600 font-medium block mb-2">
                  Remaining Amount
                </label>
                <input
                  type="text"
                  value={remainingAmount.toFixed(2)} // Ensure 2 decimal places
                  readOnly
                  className="w-full border-x-0 border-t-0 border-gray-300 rounded-none p-2 bg-gray-100 text-gray-600 cursor-not-allowed"
                />
              </div>
            </div>
            <div className="flex flex-col items-center text-center cursor-pointer">
              <div
                onClick={() =>
                  updateBillStatus(
                    selectedBill.BillID,
                    "Partially Paid",
                    paidAmount,
                    null,
                    null
                  )
                }
                className="mt-4 w-auto bg-teal-700 px-8 self-center text-white py-2 rounded-lg hover:bg-teal-600"
              >
                Update
              </div>
            </div>
          </div>
        </div>
      ) : isModalOpen == "Denied" ? (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-[768px] p-6">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-semibold text-gray-900">
                Denied Status
              </h2>
              <MdOutlineCancel
                size={40}
                className="text-gray-500 cursor-pointer hover:text-gray-800 focus:outline-none rounded-full bg-white"
                onClick={() => setModalOpen(false)}
              />
            </div>
            <div className="mt-4 flex flex-row justify-between gap-4">
              <div className="w-full">
                <label className="text-gray-600 font-medium block mb-2">
                  Denial Code
                </label>

                <input
                  type="text"
                  value={denialCode}
                  onChange={(e) => setDenialCode(e.target.value)}
                  className="w-full border-x-0 border-t-0 rounded-none border-gray-300  p-2 focus:outline-none focus:ring-0 "
                />
              </div>
              <div className=" w-full">
                <label className="text-gray-600 font-medium block mb-2">
                  Reason
                </label>
                <input
                  type="text"
                  value={denialReason} // Ensure 2 decimal places
                  onChange={(e) => setDenialReason(e.target.value)}
                  className="w-full border-x-0 border-t-0 rounded-none border-gray-300  p-2 focus:outline-none focus:ring-0 "
                />
              </div>
            </div>
            <div className="flex flex-col items-center text-center cursor-pointer">
              <div
                onClick={() =>
                  updateBillStatus(
                    selectedBill.BillID,
                    "Denied",
                    null,
                    denialCode,
                    denialReason
                  )
                }
                className="mt-4 w-auto bg-teal-700 px-8 self-center text-white py-2 rounded-lg hover:bg-teal-600"
              >
                Update
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {loading ? (
        <div className="h-[40vh] grid">
          <div className="flex flex-col items-center justify-center">
            <Loader />
            <p className="text-gray-600 pt-4 text-xl">
              Hold On, Bills are Loading...
            </p>
          </div>
        </div>
      ) : (
        <>
          {selectedBillId.length > 0 && (
            <div className="flex flex-row gap-3 justify-start w-min mb-8">
              <button
                className="border-2 border-teal-700 text-teal-700 bg-inherit text-lg rounded-lg px-3 hover:bg-teal-700 hover:text-white"
                onClick={() => changeMultiStatus(selectedBillId, "Billed")}
              >
                Billed
              </button>
              <button
                className="border-2 border-teal-700 text-teal-700 bg-inherit text-lg rounded-lg px-3 hover:bg-teal-700 hover:text-white"
                onClick={() => changeMultiStatus(selectedBillId, "Paid")}
              >
                Paid
              </button>
            </div>
          )}
          <div className="bg-white text-lg shadow-md rounded-md overflow-hidden mb-8">
            <table className="min-w-full table-auto">
              <thead>
                <tr className="text-left text-gray-400">
                  <th
                    className="px-6 py-3"
                    onClick={(e) => {
                      // e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <input
                      type="checkbox"
                      className="scale-150 cursor-pointer"
                      checked={data[resGroup].every((it) =>
                        selectedBillId.includes(it.BillID)
                      )}
                      onChange={(e) => {
                        if (
                          !data[resGroup].every((it) =>
                            selectedBillId.includes(it.BillID)
                          )
                        )
                          setSeleectedBillId(
                            data[resGroup].map((it) => it.BillID)
                          );
                        else setSeleectedBillId([]);
                      }}
                    />
                  </th>
                  <th className="px-6 py-3 font-normal">Name</th>
                  <th className="px-6 py-3 font-normal">Date of Birth</th>
                  <th className="px-6 py-3 font-normal">SSN Number</th>
                  <th className="px-6 py-3 font-normal">PMI Number</th>
                  <th className="px-6 py-3 font-normal">Prior Auth</th>
                  <th className="px-6 py-3 font-normal">Guest In Date</th>
                  <th className="px-6 py-3 font-normal">Amount Due</th>
                  <th className="px-6 py-3 font-normal">Status</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((item, index) => (
                  <tr
                    key={index}
                    className=" text-gray-900 hover:bg-gray-50 odd:bg-gray-50 even:bg-white cursor-pointer"
                    onClick={() =>
                      (window.location.href = `/dashboard/resident/${item.ResID}`)
                    }
                  >
                    <td
                      className="px-6 py-4"
                      onClick={(e) => {
                        // e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <input
                        type="checkbox"
                        className="scale-150 cursor-pointer"
                        checked={
                          selectedBillId.find((it) => it == item.BillID) ===
                          undefined
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          if (!selectedBillId.find((it) => it == item.BillID))
                            setSeleectedBillId([
                              ...selectedBillId,
                              item.BillID,
                            ]);
                          else
                            setSeleectedBillId(
                              selectedBillId.filter((i) => i !== item.BillID)
                            );
                        }}
                      />
                    </td>
                    <td className="px-6 py-4">
                      {item.ResLastName + ", " + item.ResFirstName}
                    </td>
                    <td className="px-6 py-4">
                      {dateShower(item.ResBirthDate)}
                    </td>
                    <td className="px-6 py-4">{item.SSN}</td>
                    <td className="px-6 py-4">{item.PMI || "-"}</td>
                    <td className="px-6 py-4">{item.PriorAuth || "-"}</td>
                    <td className="px-6 py-4">
                      {dateShower(item.GuestInDate)}
                    </td>
                    <td className="px-6 py-4">
                      {item.TotalAmount
                        ? "$" + Number(item.TotalAmount).toFixed(2)
                        : "No SAL"}
                    </td>
                    <td
                      className="px-6 py-4"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div
                        className={`w-36 py-2 rounded-full cursor-pointer text-center text-[16px] ${
                          item.Status === "Paid"
                            ? "bg-green-100 text-green-800"
                            : item.Status === "To Bill"
                            ? "bg-gray-200 text-gray-500"
                            : item.Status === "Billed"
                            ? "bg-blue-200 text-blue-500"
                            : item.Status === "Partially Paid"
                            ? "bg-yellow-200 text-yellow-800"
                            : item.Status === "Denied"
                            ? "bg-red-200 text-red-500"
                            : ""
                        }`}
                      >
                        <select
                          value={item.Status}
                          className={`border-none cursor-pointer text-center bg-transparent focus:outline-none focus:ring-0 ${
                            item.Status === "Paid"
                              ? "bg-green-100 text-green-800"
                              : item.Status === "To Bill"
                              ? "bg-gray-200 text-gray-500"
                              : item.Status === "Billed"
                              ? "bg-blue-200 text-blue-500"
                              : item.Status === "Partially Paid"
                              ? "bg-yellow-200 text-yellow-800"
                              : item.Status === "Denied"
                              ? "bg-red-200 text-red-500"
                              : ""
                          }`}
                          onChange={(e) =>
                            changeMultiStatus(
                              [item.BillID],
                              e.target.value,
                              item
                            )
                          }
                        >
                          {item.Status == "To Bill" ? (
                            <>
                              <option
                                value="To Bill"
                                className="cursor-pointer bg-gray-200 text-gray-500"
                              >
                                To Bill
                              </option>
                              <option
                                value="Billed"
                                className="cursor-pointer bg-blue-200 text-blue-500"
                              >
                                Billed
                              </option>
                            </>
                          ) : (
                            <>
                              <option
                                value="To Bill"
                                className="cursor-pointer bg-gray-200 text-gray-500"
                              >
                                To Bill
                              </option>
                              <option
                                value="Billed"
                                className="cursor-pointer bg-blue-200 text-blue-500"
                              >
                                Billed
                              </option>
                              <option
                                value="Partially Paid"
                                className="cursor-pointer bg-yellow-100 text-yellow-800"
                              >
                                Partially Paid
                              </option>
                              <option
                                value="Denied"
                                className="cursor-pointer bg-red-200 text-red-500"
                              >
                                Denied
                              </option>
                              <option
                                value="Paid"
                                className="cursor-pointer bg-green-100 text-green-800"
                              >
                                Paid
                              </option>
                            </>
                          )}
                        </select>
                      </div>
                    </td>

                    <td className="px-6 py-4">
                      <SlArrowRight />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {data[resGroup].length > itemsPerPage && (
            <div className="flex justify-center self-center items-center space-x-2 w-fit">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                className={`w-8 h-8  items-center justify-center ${
                  currentPage === 1
                    ? "hidden text-gray-500 cursor-not-allowed"
                    : "flex bg-white text-gray-600 hover:bg-gray-100"
                }`}
                disabled={currentPage === 1}
              >
                <SlArrowLeft />
              </button>

              {/* Page Numbers */}
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={`w-8 h-8 flex items-center justify-center rounded-lg ${
                      currentPage === page
                        ? " border-2 border-teal-600 outline-offset-0 outline-1 bg-white text-black"
                        : "bg-white text-gray-600 hover:bg-gray-100"
                    }`}
                  >
                    {page}
                  </button>
                )
              )}

              <button
                onClick={() => handlePageChange(currentPage + 1)}
                className={`w-8 h-8 items-center justify-center  ${
                  currentPage === totalPages
                    ? "hidden text-gray-500 cursor-not-allowed"
                    : "flex bg-white text-gray-600 hover:bg-gray-100"
                }`}
                disabled={currentPage === totalPages}
              >
                <SlArrowRight />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default GenerateBilling;
