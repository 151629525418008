import React from "react";

import { getCurrentUser } from "../../services/authService";
import { financeAccess } from "../../utils/roles";
import BillingManagement from "../BillingProfileManagement";
import GenerateBilling from "../../components/GenerateBilling";

function Billing() {
  const user = getCurrentUser();
  return (
    <div className="">
      <div className="reports-Container-Section">
        {financeAccess(user) && (
          <>
            <div className="flex flex-col space-y-5">
              <div>
                <div>
                  <GenerateBilling />
                </div>
              </div>
              {/* <div>
                <BillingManagement />
              </div>
              <div className="residentView-sectionBox mx-0">
                <div className="w-full flex flex-row justify-between mb-4">
                  <div>
                    <h2 className="text-3xl font-semibold text-teal-600 mb-6">
                      Previous Billing
                    </h2>
                  </div>
                  <div>
                   
                  </div>
                </div>
              </div> */}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Billing;
